import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AddEditUpcomingMatchesBlockDto,
    UpcomingMatchesBlockFiltersDto,
    UpcomingMatchesBlockResponseDto,
} from 'src/app/core/services/moveup-api/upcoming-matches-blocks/upcoming-matches-blocks.dtos';
import { Pagination } from 'src/app/shared/dtos/pagination.dtos';
import { dtoToHttpParamsV2 } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class UpcomingMatchesBlocksService {
    baseUrl = `${environment.api.baseUrl}/admin/upcoming-matches-block`;

    constructor(private http: HttpClient) {}

    getList(
        page: number,
        limit: number,
        dto: UpcomingMatchesBlockFiltersDto,
    ): Observable<Pagination<UpcomingMatchesBlockResponseDto>> {
        return this.http.get<Pagination<UpcomingMatchesBlockResponseDto>>(
            `${this.baseUrl}/list/${page}/${limit}`,
            { params: dtoToHttpParamsV2(dto) },
        );
    }

    getUpcomingMatchesBlock(id: string): Observable<UpcomingMatchesBlockResponseDto> {
        return this.http.get<UpcomingMatchesBlockResponseDto>(`${this.baseUrl}/${id}`);
    }

    add(dto: AddEditUpcomingMatchesBlockDto): Observable<UpcomingMatchesBlockResponseDto> {
        return this.http.post<UpcomingMatchesBlockResponseDto>(`${this.baseUrl}/add`, dto);
    }

    edit(
        id: string,
        dto: AddEditUpcomingMatchesBlockDto,
    ): Observable<UpcomingMatchesBlockResponseDto> {
        return this.http.put<UpcomingMatchesBlockResponseDto>(`${this.baseUrl}/edit/${id}`, dto);
    }

    remove(id: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/remove/${id}`);
    }
}
