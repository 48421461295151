import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { LayoutComponent } from 'src/app/layout/components/layout/layout.component';
import { ErrorPage } from 'src/app/layout/pages/error/error.page';
import { ForbiddenPage } from 'src/app/layout/pages/forbidden/forbidden.page';
import { NotFoundPage } from 'src/app/layout/pages/not-found/not-found.page';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: 'page-not-found',
        component: NotFoundPage,
        data: { title: `Page not found` },
    },
    {
        path: 'access-denied',
        component: ForbiddenPage,
        data: { title: `Access denied` },
    },
    {
        path: 'error',
        component: ErrorPage,
        data: { title: `Error` },
    },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            {
                path: 'home',
                loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
            },
            {
                path: 'users',
                loadChildren: () =>
                    import('./features/users/users.module').then((m) => m.UsersModule),
            },
            {
                path: 'accumulators',
                loadChildren: () =>
                    import('./features/accumulators/accumulators.module').then(
                        (m) => m.AccumulatorsModule,
                    ),
            },
            {
                path: 'games',
                loadChildren: () =>
                    import('./features/games/games.module').then((m) => m.GamesModule),
            },
            {
                path: 'bookmakers',
                loadChildren: () =>
                    import('./features/bookmakers/bookmakers.module').then(
                        (m) => m.BookmakersModule,
                    ),
            },
            {
                path: 'websites',
                loadChildren: () =>
                    import('./features/websites/websites.module').then((m) => m.WebsitesModule),
            },
            {
                path: 'competitors',
                loadChildren: () =>
                    import('./features/competitors/competitors.module').then(
                        (m) => m.CompetitorsModule,
                    ),
            },
            {
                path: 'serp',
                loadChildren: () => import('./features/serp/serp.module').then((m) => m.SerpModule),
            },
            {
                path: 'crud-example',
                loadChildren: () =>
                    import('./features/crud-example/crud-example.module').then(
                        (m) => m.CrudExampleModule,
                    ),
            },
            {
                path: 'data-tables',
                loadChildren: () =>
                    import('./features/data-tables/data-tables.module').then(
                        (m) => m.DataTablesModule,
                    ),
            },
            {
                path: 'tracking-material',
                loadChildren: () =>
                    import('./features/tracking-material/tracking-material.module').then(
                        (m) => m.TrackingMaterialModule,
                    ),
            },
            {
                path: 'scraping',
                loadChildren: () =>
                    import('./features/scraping/scraping.module').then((m) => m.ScrapingModule),
            },
            {
                path: 'affiliate-accounts',
                loadChildren: () =>
                    import('./features/affiliate-accounts/affiliate-accounts.module').then(
                        (m) => m.AffiliateAccountsModule,
                    ),
            },
            {
                path: 'cta-table-groups',
                loadChildren: () =>
                    import('./features/cta-table-groups/cta-table-groups.module').then(
                        (m) => m.CtaTableGroupsModule,
                    ),
            },
            {
                path: 'localization',
                loadChildren: () =>
                    import('./features/localizations/localizations.module').then(
                        (m) => m.LocaliaztionsModule,
                    ),
            },
            {
                path: 'upcoming-matches-blocks',
                loadChildren: () =>
                    import(
                        './features/upcoming-matches-blocks/upcoming-matches-blocks.module'
                    ).then((m) => m.UpcomingMatchesBlocksModule),
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
