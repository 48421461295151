import { CommonBookmakerService } from 'src/app/core/services/moveup-api/common/bookmaker/bookmaker.service';
import { CountryService } from 'src/app/core/services/moveup-api/common/country/country.service';
import { FixtureService } from 'src/app/core/services/moveup-api/common/fixture/fixture.service';
import { LeagueService } from 'src/app/core/services/moveup-api/common/league/league.service';
import { CommonMarketService } from 'src/app/core/services/moveup-api/common/market/market.service';
import { CommonPartnerService } from 'src/app/core/services/moveup-api/common/partner/partner.service';
import { CommonPaymentMethodService } from 'src/app/core/services/moveup-api/common/payment-method/payment-method.service';
import { TeamService } from 'src/app/core/services/moveup-api/common/team/team.service';
import { CommonUserService } from 'src/app/core/services/moveup-api/common/user/user.service';
import { CommonWebsiteService } from 'src/app/core/services/moveup-api/common/website/website.service';
import { CommonBookmakerWebsiteService } from './bookmaker-website/bookmaker-website.service';

export const MOVEUP_API_COMMON_SERVICES = [
    CountryService,
    LeagueService,
    TeamService,
    FixtureService,
    CommonMarketService,
    CommonBookmakerService,
    CommonPaymentMethodService,
    CommonWebsiteService,
    CommonPartnerService,
    CommonUserService,
    CommonBookmakerWebsiteService,
];
